/*
  According to https://github.com/styled-components/styled-components/issues/2227
  font definitions should be still placed in .css file to avoid refetching fonts and
  unnecessary rerenders
*/

@font-face {
  font-family: Graphik;
  font-weight: 200;
  src: url('assets/fonts/Graphik-Extralight-Web.woff2') format('woff2');
}

@font-face {
  font-family: Graphik;
  font-weight: 400;
  src: url('assets/fonts/Graphik-Regular-Web.woff2') format('woff2');
}

@font-face {
  font-family: Graphik;
  font-weight: 500;
  src: url('assets/fonts/Graphik-Medium-Web.woff2') format('woff2');
}

@font-face {
  font-family: Graphik;
  font-weight: 600;
  src: url('assets/fonts/Graphik-Semibold-Web.woff2') format('woff2');
}

@font-face {
  font-family: InspireTWDC;
  src: local('InspireTWDC'), url('assets/fonts/InspireTWDC/InspireTWDC-Light.otf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: InspireTWDC;
  src: local('InspireTWDC'), url('assets/fonts/InspireTWDC/InspireTWDC-Roman.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: InspireTWDC;
  src: local('InspireTWDC'), url('assets/fonts/InspireTWDC/InspireTWDC-Medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: InspireTWDC;
  src: local('InspireTWDC'), url('assets/fonts/InspireTWDC/InspireTWDC-Heavy.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: InspireTWDC;
  src: local('InspireTWDC'), url('assets/fonts/InspireTWDC/InspireTWDC-Black.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: InspireTWDC;
  src: local('InspireTWDC'), url('assets/fonts/InspireTWDC/InspireTWDC-LightItalic.otf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: InspireTWDC;
  src: local('InspireTWDC'), url('assets/fonts/InspireTWDC/InspireTWDC-Italic.otf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: InspireTWDC;
  src: local('InspireTWDC'), url('assets/fonts/InspireTWDC/InspireTWDC-MediumItalic.otf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: InspireTWDC;
  src: local('InspireTWDC'), url('assets/fonts/InspireTWDC/InspireTWDC-HeavyItalic.otf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: InspireTWDC;
  src: local('InspireTWDC'), url('assets/fonts/InspireTWDC/InspireTWDC-BlackItalic.otf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'), url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-Thin.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'),
    url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-ThinOblique.otf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'),
    url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-ExtraLight.otf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'),
    url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-ExtraLightOblique.otf') format('truetype');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'), url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'),
    url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-LightOblique.otf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'), url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-Regular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'), url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-Oblique.otf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'), url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-Medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'),
    url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-MediumOblique.otf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'), url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-SemiBold.otf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'),
    url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-SemiBoldOblique.otf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'), url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-Bold.otf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'),
    url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-BoldOblique.otf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'), url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-Black.otf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: MultiplaneTWDC;
  src: local('MultiplaneTWDC'),
    url('assets/fonts/MultiplaneTWDC/MultiplaneTWDCDisplay-BlackOblique.otf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}
